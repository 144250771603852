<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'users' }">Users</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'roles' }">Roles</router-link>
        </li>
        <li class="breadcrumb-item active">Edit Role</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
          <h2 class="title2">Edit Role</h2>
        </div>
        <div class="col-md-1 text-right">
          <router-link :to="{ name: 'roles' }" class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <form @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="mb-3">
                  <label class="form-label">Role name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="$v.form.role_name.$model"
                    :validator="$v.form.role_name"
                    id="employee"
                  />
                  <span class="error" v-if="!$v.form.role_name.required"
                    >Role name is required</span
                  >
                </div>
                <div class="mb-3">
                  <label class="form-label">Status</label>
                  <b-form-select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.state.$model"
                    :validator="$v.form.state"
                    :options="status"
                  ></b-form-select>
                  <span class="error" v-if="!$v.form.state.required"
                    >Status is required</span
                  >
                </div>
                <div class="text-right">
                  <button type="submit" class="btn btn-outline-primary space">
                    Save
                  </button>
                  <router-link
                    :to="{ name: 'roles' }"
                    class="btn btn-outline-primary"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
require("../../axios.js");

export default {
  data() {
    return {
      roleData: null,
      form: {
        id: null,
        role_name: null,
        state: null,
      },
      status: [
        {
          value: "Active",
          text: "Active",
        },
        {
          value: "Disabled",
          text: "Disabled",
        },
      ],
    };
  },
  validations: {
    form: {
      id: {},
      role_name: {
        required,
      },
      state: {
        required,
      },
    },
  },

  mounted() {
    this.roleData = this.$store.state.roles.filter((role) => {
      return role.id == this.$route.params.id;
    })[0];
    this.form.role_name = this.roleData.role_name;
    this.form.id = this.$route.params.id;
    this.form.state = this.roleData.state;
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      axios
        .post("users/roles/update", this.form)
        .catch(({ response }) => {
          this.flashMessage.error({
            message: "Something went wrong!",
            time: 3000,
          });
        })
        .then(({ data }) => {
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$router.push({ name: "roles" });
          } else {
            this.flashMessage.error({
              message: data.data.error,
              time: 3000,
            });
          }
        });
    },
  },
};
</script>
